<template>
  <v-app>

    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row ">
              <div class="col-md-12">
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                <router-link :to="{name:'email-template'}">
                  Email Template
                </router-link>
                \
                Update Email Template
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>Email Template</h4>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3">

              </div>
              <v-row>
                <v-col cols="8">
                  <v-col cols="12">
                    <h6>{{ email_template.title }}</h6>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        outlined
                        dense
                        label="Email Subject"
                        v-model="email_template.subject"
                    >

                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <strong>
                      Content
                    </strong>
                    <ckeditor v-model="email_template.description"></ckeditor>
                  </v-col>
                </v-col>
                <v-col cols="4">

                  <v-col cols="12">
                    <strong>Accepted Inputs</strong>
                  </v-col>

                  <v-col cols="12" v-if="accepted_inputs.length">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-for="(input,index) in accepted_inputs " :key="index"
                               class="ma-2 text-lowercase"
                               color="success"
                               outlined
                               label
                               dark
                               v-bind="attrs"
                               v-on="on"
                               v-clipboard:copy="`{{${input}}}`"
                               v-clipboard:success="onCopy"
                               v-clipboard:error="onError"
                        >
                          {{ input }}
                        </v-btn>
                      </template>
                      <span>Copy</span>
                    </v-tooltip>

                  </v-col>
                  <v-col cols="12">
                    <v-switch label="Active" v-model="email_template.is_active">

                    </v-switch>
                  </v-col>
                  <v-col cols="12">
                    <v-btn @click.prevent="__update" :loading="is_loading" class="btn btn-primary text-white" depressed>
                      Save
                    </v-btn>
                    <v-btn @click="redirectedTo" class="btn btn-secondary ml-2" depressed>
                      Cancel
                    </v-btn>
                  </v-col>

                </v-col>
              </v-row>

            </div>
          </div>
        </div>
      </div>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </div>
  </v-app>
</template>
<script>
import EmailTemplateService from "@/services/email-template/EmailTemplateService";

const emailTemplate = new EmailTemplateService();
export default {
  name: "Email-Template",
  components: {},
  data() {
    return {
      text: null,
      timeout: 2000,
      is_loading: false,
      snackbar: false,
      email_template: null,
      email_templates: [],
      accepted_inputs: [],
      email_types: [
        {name: 'Pre Registration Acknowledge', value: 'pre_registration_acknowledge'},
      ],
      items: [],
      search: null,
    };
  },
  mounted() {
    this.getSingleEmailTemplate(this.email_template_id);
  },
  computed: {
    email_template_id() {
      return this.$route.params.id;
    }
  },
  watch: {
    model(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.model.pop())
      }
    },
  },
  methods: {
    getSingleEmailTemplate(id) {
      emailTemplate
          .show(id)
          .then(response => {
        this.email_template = response.data.email_template;
        if (this.email_template.accepted_inputs) {
          this.accepted_inputs = this.email_template.accepted_inputs.split(',');
        }
      }).catch(() => {}).finally(() => {})
    },
    redirectedTo() {
      this.$router.push({
        name: "email-template",
        params: {role: this.email_template.role}
      });
    },
    __update() {
      this.is_loading = true;
      this.email_template.accepted_inputs = this.accepted_inputs.join(',');
      emailTemplate.update(this.email_template.id, this.email_template).then(response => {
        this.$snotify.success('Updated Successfully')
        this.getSingleEmailTemplate(this.email_template_id);
        this.$tabs.close();
        this.$router.push({name: "email-template"});
      }).catch(error => {

      }).finally(() => {
        this.is_loading = false;

      })
    },

    deleteAcademicYear(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item permanently?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {

          }
        }
      });
    },

    onCopy: function (e) {
      this.text = "You just copied accepted input ";
      this.snackbar = true;
    },
    onError: function (e) {
      this.text = "Failed to copy accepted input";
      this.snackbar = true;
    },
  }
};
</script>
